import React from 'react';
import Location from './src/context/location';
import Language, { defaultLanguage } from './src/context/language';
import Translations from './src/context/translations';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({
  element,
  props: { location, pageContext },
}) => (
  <Location.Provider value={location}>
    <Language.Provider value={pageContext?.wpml_current_locale || defaultLanguage}>
      <Translations.Provider value={pageContext?.wpml_translations || []}>
        {element}
      </Translations.Provider>
    </Language.Provider>
  </Location.Provider>
);

import i18n from 'gatsby-theme-nodust/src/gatsby-theme-wordpress/context/i18n';

export default {
  nl: i18n.nl,
  en: i18n.en,
  fr: i18n.fr,
  de: i18n.de,
  es: i18n.es,
  'pt-pt': i18n['pt-pt'],
};

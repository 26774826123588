import { createContext, useContext } from 'react';
import defaultLanguage from './default-language';
import i18n from './i18n';

export {
  defaultLanguage,
  i18n,
};

const Language = createContext(defaultLanguage);

export const useLanguage = () => useContext(Language);

export const useI18n = () => {
  const language = useLanguage();
  return i18n[language];
};

export default Language;

module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"wordpress":[{"protocol":"https","domain":"nodustpm10.lignostar.com","language":"nl","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustpm10.lignostar.com","language":"en","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustpm10.lignostar.com","language":"de","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustpm10.lignostar.com","language":"fr","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustpm10.lignostar.com","language":"es","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustpm10.lignostar.com","language":"pt-pt","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NoDustPM10.com","short_name":"NoDustPM10","start_url":"/?pwa=true","background_color":"black","theme_color":"#f46704","display":"minimal-ui","icon":"src/images/favicon.png","lang":"nl","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/de/","lang":"de"},{"start_url":"/fr/","lang":"fr"},{"start_url":"/es/","lang":"es"},{"start_url":"/pt-pt/","lang":"pt"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b077c73f83a1196a7e3e0bb71cb68c0b"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CXS90B1T4M","UA-36988470-5"],"pluginConfig":{"head":false,"respectDNT":true,"anonymize_ip":true,"exclude":[]}},
    }]

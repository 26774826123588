export default {
  nl: {
    label: 'Nederlands',
    abbr: {
      tel: 't',
      fax: 'f',
      email: 'e',
      website: 'w',
    },
    readMore: 'lees meer',
    moreProjectInfo: 'lees meer',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
  },
  de: {
    label: 'Deutsch',
    abbr: {
      tel: 't',
      fax: 'f',
      email: 'e',
      website: 'i',
    },
    readMore: 'mehr info',
    moreProjectInfo: 'mehr Projektinfo',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
  },
  en: {
    label: 'English',
    abbr: {
      tel: 't',
      fax: 'f',
      email: 'e',
      website: 'w',
    },
    readMore: 'read more',
    moreProjectInfo: 'read more',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
  },
  'pt-pt': {
    label: 'Português',
    abbr: {
      tel: 't',
      fax: 'f',
      email: 'e',
      website: 'w',
    },
    readMore: 'leia mais',
    moreProjectInfo: 'leia mais',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
  },
  fr: {
    label: 'Français',
    abbr: {
      tel: 't',
      fax: 'f',
      email: 'e',
      website: 'w',
    },
    readMore: 'read more',
    moreProjectInfo: 'read more',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
  },
  es: {
    label: 'Español',
    abbr: {
      tel: 't',
      fax: 'f',
      email: 'e',
      website: 'w',
    },
    readMore: 'read more',
    moreProjectInfo: 'read more',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
  },
};
